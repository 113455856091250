import React from 'react';
// import LoginPage from 'pages/LoginPage';
import { Route, Switch } from 'react-router-dom';

import { loginRouteTemplates } from '../routeTemplates';

function LoginRouting() {
	return (
		<Switch>
			<Route
				path={loginRouteTemplates.loginPage.path}
				component={loginRouteTemplates.loginPage.component}
			/>
			<Route
				exact
				path={loginRouteTemplates.EmailConfirmationPage.path}
				component={loginRouteTemplates.EmailConfirmationPage.component}
			/>
		</Switch>
	);
}

export default LoginRouting;
